import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private toast = inject(ToastrService);


  handleError(error?: { error: { code: string }; status: number | string }, customTitle = 'Une erreur est survenue') {
    let errorMessage = 'Merci de réessayer plus tard';

    if (error) {
      switch (error.error.code) {
        case 'end-date-ealier-than-start-date':
          errorMessage = 'La date de fin doit être postérieure à la date de début.';
          break;

        case 'user-cannot-update-his-own-permissions':
          errorMessage = 'Vous ne pouvez pas modifier vos propres permissions';
          break;

        case 'practitioner-must-have-at-least-one-profession':
          errorMessage = 'Un praticien doit avoir une profession';
          break;

        case 'user-not-found':
          errorMessage = 'Utilisateur non trouvé';
          break;

        case 'user-does-not-have-all-required-permissions':
        case 'structure-entity-permission-missing':
        case 'practitioner-entity-permission-missing':
          errorMessage = "Vous n'avez pas les permissions requises";
          break;

        case 'Unauthorized':
          errorMessage = 'Action non autorisée';
          break;

        case 'invalid-token':
          errorMessage = 'La demande a expirée';
          break;

        case 'structure-equipment-or-patient-must-at-least-be-included':
          errorMessage = 'La cession doit inclure la structure, les équipements ou les patients';
          break;

        case 'endDate-must-be-after-startDate':
          errorMessage = 'La date de fin dois être après la date de début';
          break;

        case 'structure-creation-date-must-be-before-today':
          errorMessage = "L'établissement ne doit pas avoir été créé aujourd'hui";
          break;

        case 'structure-not-found':
          errorMessage = 'Établissement non trouvé';
          break;

        case 'practitioner-to-replace-not-found':
          errorMessage = 'Praticien non trouvé';
          break;

        case 'mission-not-found':
          errorMessage = 'Annonce non trouvée';
          break;

        case 'exercise-cannot-be-changed':
          errorMessage = "Le type d'annonce ne peut pas être modifié";
          break;
      }
    }
    if (errorMessage) {
      this.toast.error(errorMessage, customTitle);
    }
  }
}
